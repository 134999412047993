import React from 'react';
import styled from 'styled-components';
import { Container, Section } from '../components/global';
import Layout from '../components/common/layout/layout';
import SEO from '../components/common/layout/seo';
import Navigation from '../components/common/navigation/navigation';
import Footer from '../components/sections/footer';
import EscrowIcon from './features/assets/dealManagement/escrow.png';
import DealTemplateIcon from './features/assets/dealManagement/template.png';
import QuickInvoiceIcon from './features/assets/dealManagement/invoices.png';
import PaymentIcon from './features/assets/dealManagement/payment.png';
import DashboardIcon from './features/assets/dealManagement/dashboardIcon.png';
import RealEstateAccountingIcon from './features/assets/dealManagement/real_estate_accounting.png';
import NewDealFromTemplateGif from './features/assets/dealManagement/new_deal_from_template.gif';
import MapperFillVouchers from './features/assets/dealManagement/deal_mapper_fill_vouchers.gif';
import InvoicingADealGif from './features/assets/dealManagement/invoicing_a_deal.gif';
import TwoTenantRental from './features/assets/dealManagement/screenshots/two_tenant_rental.png';
import DealTemplates from './features/assets/dealManagement/screenshots/deal_templates.png';
import Dashboard from './features/assets/dealManagement/screenshots/dashboard.png';
import Deal1 from './features/assets/dealManagement/screenshots/deal_1.png';
import Deal2 from './features/assets/dealManagement/screenshots/deal_2.png';
import Deal3 from './features/assets/dealManagement/screenshots/deal_3.png';
import Deal4 from './features/assets/dealManagement/screenshots/deal_4.png';
import VoucherType from './features/assets/dealManagement/screenshots/voucher_type.png';
import CustomizeIcon from './features/assets/dealManagement/customize.png';
import SecurityIcon from './features/assets/dealManagement/security.png';
import DataSecurityIcon from './features/assets/dealManagement/data_security.png';
import AccountingDetails from './features/assets/dealManagement/screenshots/accounting_details.png';
import Permissions from './features/assets/dealManagement/screenshots/permissions.png';
import TransactionsIcon from './features/assets/dealManagement/transactions.png';
import FinancialAccountsIcon from './features/assets/dealManagement/financial_accounts.png';
import InsightIcon from './features/assets/dealManagement/insight.png';
import AccountingIcon from './features/assets/dealManagement/accounting.png';
import Report from './features/assets/dealManagement/screenshots/report.png';
import ChartOfAccounts from './features/assets/dealManagement/screenshots/chart_of_accounts.png';
import ConnectToXero from './features/assets/dealManagement/screenshots/connect_to_xero.png';
import IntegrationIcon from './features/assets/dealManagement/integration.png';

const FeaturesPage = () => {
  return (
    <Layout>
      <SEO title="Features" />
      <Navigation />
      <TagBlock id="deal-management" />
      <StyledSection>
        <SummaryContainer>
          <CardContainer>
            <div style={{ display: 'flex' }}>
              <SummaryTitle>Smart Deal Management</SummaryTitle>
            </div>
            <SectionHeader style={{ color: '#FF934F', marginBottom: '32px', marginTop: '0px' }}>
              For Real Estate Brokers & Agencies
            </SectionHeader>
            <CardContent style={{ maxWidth: '900px', margin: '0 auto' }}>
              Deal Map is accounting software designed for real estate brokers and agents. It makes
              tracking and organizing a sale or rental agreement quick and easy. Tracking finances
              for a real estate deal can be challenging. With Deal Map you can take the guesswork
              out of your real estate agency's accounting.
            </CardContent>
            <Bucket style={{ marginTop: '6rem' }}>
              <div style={{ width: '47%' }}>
                <SectionHeader style={{ marginTop: '0px' }}>
                  <img
                    src={RealEstateAccountingIcon}
                    alt="template icon"
                    style={{ width: '60px', marginRight: '10px' }}
                  />
                  Real Estate Accounting
                </SectionHeader>
                <CardContent>
                  Enter everything you need to know about a deal in one place and let the system do
                  the rest.
                </CardContent>
                <CardContent>
                  <h5>Deal Map will automatically:</h5>
                  <ul>
                    <li>Track your income and escrow balances and organize them</li>
                    <li>Make the necessary entries in your accounting system</li>
                    <li>
                      Show you when invoices have been paid, so that you aren’t sending money out
                      the door before you receive it
                    </li>
                    <li>Run financial reports by deal to better understand your business</li>
                  </ul>
                </CardContent>
              </div>
              <img
                src={MapperFillVouchers}
                alt="gif 2"
                style={{ width: '49%', marginTop: '16px' }}
                className="screenshot"
              />
            </Bucket>
            <br />
            <br />
            <StandaloneText>
              <CardContent>
                Brokering a deal is never easy and it can be distracting to be chasing money around.
                Accounting software is necessary for tracking business activities you need to close
                a deal. It is often not great at buying things on{' '}
                <i>
                  <b>behalf</b>
                </i>{' '}
                of a customer.
              </CardContent>
            </StandaloneText>
            <br />
            <SectionHeader>
              <img
                src={DealTemplateIcon}
                alt="template icon"
                style={{ width: '60px', marginRight: '10px' }}
              />
              Deal Templates
            </SectionHeader>
            <Bucket>
              <div style={{ width: '48%' }}>
                <h5>You choose exactly how you do business.</h5>
                <CardContent>
                  Deals come in all shapes and sizes. No more extra fields left blank. Multi-tenant
                  rentals are easy to manage with Deal Map.
                </CardContent>
                <CardContent>
                  Deal Map lets you enter multiple tenants in one place to generate separate
                  invoices with a few clicks.
                </CardContent>
              </div>
              <img
                src={TwoTenantRental}
                alt="two tenant"
                style={{ width: '47%' }}
                className="screenshot"
              />
            </Bucket>
            <Bucket style={{ marginTop: '6rem' }}>
              <img
                src={DealTemplates}
                alt="deal templates"
                style={{ width: '60%' }}
                className="screenshot"
              />
              <div style={{ width: '37%' }}>
                <h5>Customize your workflow.</h5>
                <CardContent>
                  Choose from our library of templates. Make them your own by specifying your
                  transaction categories and codes or design your own templates from scratch.
                </CardContent>
              </div>
            </Bucket>

            <br />
            <br />
            <SectionHeader>
              <img
                src={QuickInvoiceIcon}
                alt="quick invoice icon"
                style={{ width: '60px', marginRight: '10px' }}
              />
              Quickly Create Invoices
            </SectionHeader>
            <Bucket>
              <div style={{ width: '47%' }}>
                <CardContent>
                  Take your finished deals and invoice them with the click of a button. Deal Map
                  will take all the transactions on a deal and create one clean invoice for each
                  party involved in the deal. Choose to review the invoices first or if you are
                  ready as approved invoices.
                </CardContent>
              </div>
              <img
                src={InvoicingADealGif}
                alt="invoicing a deal"
                style={{ width: '49%' }}
                className="screenshot"
              />
            </Bucket>
            <br />
            <br />
            <SectionHeader>
              <img
                src={DashboardIcon}
                alt="dashboard icon"
                style={{ width: '60px', marginRight: '10px' }}
              />
              Dashboards
            </SectionHeader>
            <StandaloneText>
              <CardContent>
                Get a company-wide view of incomplete or unprofitable deals, monthly income, and top
                performing agents.
              </CardContent>
              <CardContent>Design custom reports and include them on the dashboard.</CardContent>
            </StandaloneText>
            <br />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={Dashboard}
                alt="dashboard"
                style={{ width: '100%', maxWidth: '900px' }}
                className="screenshot"
              />
            </div>
            <Bucket id="spaced-bucket">
              <div style={{ width: '47%' }}>
                <CardContent>
                  <h5 style={{ marginTop: '0px' }}>
                    View and edit all details of a deal on the Deal Dashboard.
                  </h5>
                  <ul>
                    <li>Deal information and audit logs</li>
                    <li>Financial impact of the deal, and transaction status</li>
                    <li>Manually edit deal transactions for complete flexibility</li>
                    <li>See all linked financial records</li>
                  </ul>
                </CardContent>
              </div>
              <div style={{ width: '47%' }}>
                <img src={Deal1} alt="deal 1" style={{ width: '100%' }} className="screenshot" />
                <img src={Deal2} alt="deal 2" style={{ width: '100%' }} className="screenshot" />
              </div>
            </Bucket>
            <br />
            <SectionHeader>
              <img
                src={PaymentIcon}
                alt="payment icon"
                style={{ width: '60px', marginRight: '10px' }}
              />
              Payment Partners
            </SectionHeader>
            <StandaloneText>
              <CardContent>
                Set up electronic ACH payments with{' '}
                <a
                  href="https://central.xero.com/s/article/GoCardless-US#AddGoCardlessasapaymentservice"
                  target="_blank"
                  rel="noreferrer"
                >
                  Xero
                </a>{' '}
                and{' '}
                <a href="https://gocardless.com/xero/" target="_blank" rel="noreferrer">
                  GoCardless
                </a>{' '}
                to let your customers make low-cost electronic payments. These are automatically
                deposited, reconciled, and reflected on the Deal dashboard.
              </CardContent>
              <CardContent>
                Don’t want to use invoices?{' '}
                <a
                  href="https://support.dealmap.com/support/solutions/articles/67000689488"
                  target="_blank"
                  rel="noreferrer"
                >
                  Set your organization to record direct payments instead.
                </a>
              </CardContent>
            </StandaloneText>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={VoucherType}
                alt="voucher type"
                style={{ width: '50%', maxWidth: '400px' }}
                className="screenshot"
              />
            </div>
            <br />
            <StandaloneText>
              <CardContent>
                If your deal requires changes, make the changes on the deal, then re-invoice. Deal
                Map will make the updates for you, modify existing invoices, or make new invoices
                depending on the status of existing invoices.
              </CardContent>
              <CardContent>
                See{' '}
                <a
                  href="https://support.dealmap.com/support/solutions/articles/67000688981-using-invoice-payments"
                  target="_blank"
                  rel="noreferrer"
                >
                  how to manage existing invoices and transactions
                </a>{' '}
                that aren't already connected to a deal.
              </CardContent>
            </StandaloneText>
            <br />
            <SectionHeader>
              <img
                src={AccountingIcon}
                alt="approvals icon"
                style={{ width: '60px', marginRight: '10px' }}
              />
              Approvals
            </SectionHeader>
            <StandaloneText>
              <CardContent>
                Use Deal Map to manage your deal approval process. Agents can create and work on
                deals. Accountants can approve payments. Administrators can review deals submitted
                by agents for approval or create pre-approved deals for other agents to work on
                further.
              </CardContent>
            </StandaloneText>
            <TagBlock id="accounting" />
            <br />
            <br />
            <br />
            <div style={{ display: 'flex' }}>
              <SummaryTitle>Accounting</SummaryTitle>
            </div>
            <StandaloneText>
              <CardContent>
                Most accounting systems have a hard time aggregating all the various AP and AR
                transactions to account for the purchase and sale of a single item. Project modules,
                custom attributes, or clever naming conventions can be used to group the
                transactions but doesn’t provide the direct link that show what paid for what.
              </CardContent>
            </StandaloneText>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={AccountingDetails}
                alt="accounting details"
                style={{ width: '100%', maxWidth: '700px' }}
                className="screenshot"
              />
            </div>
            <br />
            <SectionHeader>
              <img
                src={InsightIcon}
                alt="insight icon"
                style={{ width: '60px', marginRight: '10px' }}
              />
              Deal Insights
            </SectionHeader>
            <StandaloneText>
              <CardContent>
                Deal Map lets you run analyses by Deal, Agent, and other attributes that are hard to
                track in traditional systems. Manage your business, get actionable insights, and
                view agent activity all in one place.
              </CardContent>
            </StandaloneText>
            <br />
            <Bucket>
              <div style={{ width: '67%' }}>
                <img src={Report} alt="report" style={{ width: '100%' }} className="screenshot" />
              </div>
              <div style={{ width: '37%' }}>
                <CardContent>
                  <ul>
                    <li>Show income by agent, deal, or contact</li>
                    <li>Easily track your escrow accounts and cashflow</li>
                    <li>Manage agent performance and track income over time </li>
                  </ul>
                </CardContent>
              </div>
            </Bucket>
            <br />
            <br />
            <SectionHeader>
              <img
                src={EscrowIcon}
                alt="escrow icon"
                style={{ width: '60px', marginRight: '10px' }}
              />
              Escrow
            </SectionHeader>
            <StandaloneText>
              <CardContent>
                Deal Map helps make sure you know what your escrow balance is on each deal. Easily
                identify deals set up with a non-zero escrow balance.
              </CardContent>
              <CardContent>
                Deal Map allows you to be certain that when money goes out of your escrow account,
                it is going to pay for the same exact item that you have already received money for.
              </CardContent>
            </StandaloneText>
            <br />
            <br />
            <SectionHeader>
              <img
                src={TransactionsIcon}
                alt="transactions icon"
                style={{ width: '60px', marginRight: '10px' }}
              />
              Manage Invoices and Transactions
            </SectionHeader>
            <Bucket>
              <div style={{ width: '47%' }}>
                <CardContent>
                  Managing a multi-party deal is no party. Money gets wired, cash changes hands,
                  invoices get sent, modified, and re-sent, checks are hand-written, and nothing
                  goes to plan.
                </CardContent>
                <CardContent>
                  It takes effort to manage it all with precision and can be time-consuming and
                  difficult to track.
                </CardContent>
                <CardContent>
                  We take the unmanageable mess of invoices, credit notes, and direct payments, then
                  put them into a list called Unmapped Transactions. Search for the deal that the
                  associated transaction and connect it with one click. The system will find the
                  relevant transaction - or create a new one, based on the details of the
                  transaction.
                </CardContent>
                <CardContent>
                  If no deal exists simply load the transactions and generate a new Deal to manage
                  the invoices and transactions going forward. No more chasing details and missing
                  information.
                </CardContent>
              </div>
              <div style={{ width: '49%' }}>
                <img
                  src={Deal3}
                  alt="deal 3"
                  style={{ width: '100%', maxWidth: '900px' }}
                  className="screenshot"
                />
                <img
                  src={Deal4}
                  alt="deal 4"
                  style={{ width: '100%', maxWidth: '900px' }}
                  className="screenshot"
                />
              </div>
            </Bucket>
            <br />
            <br />
            <SectionHeader>
              <img
                src={FinancialAccountsIcon}
                alt="financial accounts icon"
                style={{ width: '60px', marginRight: '10px' }}
              />
              Setup Your Financial Accounts
            </SectionHeader>
            <br />
            <Bucket>
              <img
                src={ChartOfAccounts}
                alt="chart of accounts"
                style={{ width: '59%' }}
                className="screenshot"
              />
              <div style={{ width: '37%' }}>
                <CardContent>
                  Manage the list of available transaction categories to greatly reduce the number
                  of input mistakes on your general ledger. GL accounts are set on the category but
                  can be overridden if needed. New categories are easy to create and assign accounts
                  to. Once setup, this enables the app to organize and categorize data in useful
                  ways.
                </CardContent>
              </div>
            </Bucket>

            <TagBlock id="integration" />
            <br />
            <br />
            <br />
            <div style={{ display: 'flex' }}>
              <SummaryTitle>Integration</SummaryTitle>
            </div>

            <br />
            <SectionHeader>
              <img
                src={IntegrationIcon}
                alt="data security icon"
                style={{ width: '60px', marginRight: '10px' }}
              />
              Connect To Xero
            </SectionHeader>

            <Bucket>
              <div style={{ width: '57%' }}>
                <CardContent>
                  Deal Map can be used as a stand-alone product. We recommend integrating with Xero
                  to unlock additional features and extend functionality. Invoices or Direct
                  Payments are integrated in both directions in near-real-time, allowing users to
                  seamlessly switch between systems when working.
                </CardContent>
                <CardContent>
                  Deal Map users can also take advantage of Xero’s application partners to provide
                  payment services. Customers can receive an email and easily set up ACH payments.
                </CardContent>
              </div>
              <img
                src={ConnectToXero}
                alt="connect to xero"
                style={{ width: '39%' }}
                className="screenshot"
              />
            </Bucket>
            <TagBlock id="security" />
            <br />
            <br />
            <br />
            <div style={{ display: 'flex' }}>
              <SummaryTitle>Security</SummaryTitle>
            </div>
            <SectionHeader>
              <img
                src={DataSecurityIcon}
                alt="data security icon"
                style={{ width: '60px', marginRight: '10px' }}
              />
              Data Access & Account Security
            </SectionHeader>
            <StandaloneText>
              <CardContent>
                We use best practices at every turn and aim to prioritize user privacy and security
                when developing our product. Our app uses Auth0, a leading software security
                provider trusted by many services and applications.
              </CardContent>
              <CardContent>
                As part of our integration process, Deal Map has completed a review with Xero. You
                can even use Xero to sign in to Deal Map.
              </CardContent>
            </StandaloneText>
            <br />
            <SectionHeader>
              <img
                src={CustomizeIcon}
                alt="customize icon"
                style={{ width: '60px', marginRight: '10px' }}
              />
              Customize User Permissions
            </SectionHeader>
            <Bucket style={{ marginTop: '6rem' }}>
              <img
                src={Permissions}
                alt="permissions"
                style={{ width: '29%' }}
                className="screenshot"
              />
              <div style={{ width: '67%' }}>
                <CardContent>
                  Deal Map offers user level access control with role-based permissions. Admins have
                  control over who can access what information and different features. Custom roles
                  allow you to decide who in the organization can see and edit different types of
                  data.
                </CardContent>
                <CardContent>
                  Assign detailed permissions to your agents and choose whether they can see all
                  deals, or just their own.
                </CardContent>
              </div>
            </Bucket>
            <br />
            <SectionHeader>
              <img
                src={SecurityIcon}
                alt="security icon"
                style={{ width: '60px', marginRight: '10px' }}
              />
              Encrypted Data
            </SectionHeader>
            <StandaloneText>
              <CardContent>
                Securely storing and accessing your data is our top priority. You can rest assured
                your data has the industry-leading security protocols in place. We use encrypted
                database on secure AWS servers to store your data.
              </CardContent>
            </StandaloneText>
          </CardContainer>
        </SummaryContainer>
      </StyledSection>
      <Footer />
    </Layout>
  );
};

export default FeaturesPage;

const TagBlock = styled.div`
  display: initial;
  padding-top: 66px;
`;

const Bucket = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    flex-direction: column;
    div {
      width: 100% !important;
    }
  }
`;

const StandaloneText = styled.div`
  max-width: 900px;
  //text-align: justify;
  margin: 0 auto;
`;

const StyledSection = styled(Section)`
  margin-top: 66px;
  background-color: ${(props) => props.theme.color.background.light};
  @media (max-width: ${(props) => props.theme.screen.md}) {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    margin-top: 20px;
  }
  h5 {
    font-size: 20px;
  }
  p {
    font-size: 19px;
  }
  .screenshot {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    border-radius: 5px;
    @media (max-width: ${(props) => props.theme.screen.md}) {
      width: 100% !important;
    }
  }
  li {
    text-align: left;
  }
  #spaced-bucket {
    margin-top: 7rem;
    @media (max-width: ${(props) => props.theme.screen.md}) {
      margin-top: 1rem;
    }
  }
`;

const CardContainer = styled.div`
  width: 1200px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    width: 700px;
    margin-bottom: 20px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 80%;
  }
`;
const CardContent = styled.p`
  line-height: 1.6;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    text-align: center;
  }
`;
const SummaryContainer = styled(Container)`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  // padding: 80px 0 40px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    flex-direction: column;
    padding: 0;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    img {
      width: 90%;
    }
  }
`;

const SummaryTitle = styled.h3`
  color: #01a1e8;
  color: #114b5f;
  //margin: 0 auto 32px;
  //text-align: center;
  margin: 0px;
  margin-bottom: 22px;
  display: flex;
`;

const SectionHeader = styled.h4`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  //color: #ff934f;
`;
